














import CommunityUtils, { Community } from '@/utils/CommunityUtils';
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Object as () => Partial<Community>,
    },
    mode: {
      type: String as () => 'create' | 'edit',
      required: true,
    },
    'error-messages': {
      type: Object as () => { [key: string]: string[] },
      default: () => ({}),
    },
  },

  computed: {
    internal: {
      get(): Partial<Community> {
        return this.value || CommunityUtils.community.generateDefault();
      },
      set(val: Partial<Community>) {
        this.$emit('input', val);
      },
    },
  },
});
